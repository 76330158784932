.object-cover {
  object-fit: cover;
}

.container-fluid {
  padding: 0 6.25em;
}

.section-spacing {
  padding: 3.75rem 0;
}

.section-spacing-top {
  padding: 3.75rem 0 0;
}

.section-spacing-bottom {
  padding: 0 0 3.75rem;
}

.font-size-6 {
  font-size: 0.375rem;
}

.font-size-10 {
  font-size: 0.625rem;
}

.font-size-12 {
  font-size: 0.75rem;
}

.font-size-14 {
  font-size: 0.875rem;
}

.font-size-18 {
  font-size: 1.125rem;
}

.font-size-20 {
  font-size: 1.250rem;
}

.line-count-1 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-count-2 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-count-3 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.line-count-4 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.line-count-5 {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
}

.letter-spacing-1 {
  letter-spacing: 0.0625rem;
}

.letter-spacing-2 {
  letter-spacing: 0.125rem;
}

.fw-500 {
  font-weight: 500;
}

.mb-40 {
  margin-bottom: 2.5rem;
}

.overflow-x-scroll {
  white-space: nowrap;
  flex-wrap: nowrap;
  overflow-x: auto !important;
  overflow-y: hidden;
}

.overflow-y-scroll {
  flex-wrap: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
}

.border-gredient-left {
  border-left: 0.25em solid var(--bs-primary);
  background: rgba(0, 0, 0, 0) linear-gradient(270deg, rgba(var(--bs-primary-rgb), 0), rgba(var(--bs-primary-rgb), 0.3));
}

.border-gredient-center {
  border: solid;
  border-width: 0 0 0.063em;
  border-image-source: linear-gradient(270deg, rgba(var(--bs-secondary-rgb), 0), rgba(var(--bs-secondary-rgb), 0.6), rgba(var(--bs-secondary-rgb), 0));
  border-image-slice: 1;
}

.texture-text {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  line-height: normal;

}

.big-font-5 {
  font-size: 5rem;
  font-weight: 800;
}

.cursor-pointer{
  cursor: pointer;
}

#no_result:empty, #search_list:has(#results:empty){
  display: none;
}

@media (min-width: 1200px) {
  .row-cols-xl-7 > * {
    flex: 0 0 auto;
    width: 14.285714%;
  }
}

@media (max-width: 1399.98px) {
  .container-fluid {
    padding: 0 2rem;
  }
}

@include media-breakpoint-down(lg) {
  .texture-text {
    font-size: 3.4375rem !important;
  }
}

@include media-breakpoint-down(md) {
  .container-fluid {
    padding: 0 1rem;
  }

  .texture-text {
    font-size: 1.5615rem !important;
  }

  .section-spacing {
    padding: 3.125rem 0;
  }

  .section-spacing-top {
    padding: 3.125rem 0 0;
  }

  .section-spacing-bottom {
    padding: 0 0 3.125rem;
  }

  .swiper-wrapper {
    .swiper-slide {
      padding: 0 .4687rem;
    }
  }
}
