.user-login-card {
    padding: 2.5em;
    margin: 0 auto;
    backdrop-filter: blur(1.5625em);
    .auth-heading {
      margin-bottom: 2rem;
    }
    .input-group {
        border-bottom: 1px solid var(--bs-border-color);
        margin-bottom: 1.75rem;
        .form-control {
            padding: .786rem .75rem;
            &:focus {
                border-color: transparent;
            }
        }
    }
    .logo {
        height: 45px;
    }
    .full-button {
        margin-top: 3.75rem;
    }    
  }

.border-style {
    position: relative;
    display: block;
    text-align: center;
    margin: 3rem 3rem;
    span {
        position: relative;
        display: inline-block;
        background-color: var(--bs-gray-900);
        padding: 0 1.3rem;
        z-index: 1;
    }
    &::after {
        position: absolute;
        content: "";
        top: .7rem;
        left: 0;
        right: 0;
        height: 2px;
        background: var(--bs-border-color);
    }
}